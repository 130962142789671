
/* -------------------------------------------------------------------------------- /
  Zodiac framework
  Design kit for Malaga template
  https://www.hembarevskyy.com/projects/zodiac/
  Last Updated: 02-02-2021
/ -------------------------------------------------------------------------------- */


.jtpl-header {
    -webkit-box-shadow: 0px 0px 18px 1px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 18px 1px rgb(0 0 0 / 10%);
}

.jtpl-section-main {
    margin: 85vh auto 0;
    background:#fff;
    max-width:inherit!important;
}

@media only screen and (max-width: 745px) {
    .jtpl-section-main {
     margin: 45vh auto 0;
 }

 .jtpl-background-area {
     position:absolute;
     height:45vh;
 }
}

.jtpl-topbar-section,.jtpl-topbar-section,.jtpl-navigation  {
    margin: 0 auto;
    max-width: 1170px;
}

.jtpl-navigation .j-nav-level-1, .jtpl-navigation .j-nav-level-2 {
    box-shadow: 0 7px 11px 0 rgba(0,0,0,.2);
    border: 0px solid #aaa;
    border: 0px solid rgba(0,0,0,.1);
    min-width: 180px;
    text-align: left;
}

.jtpl-navigation .j-nav-level-2 {
    border-left: none;
    left: -100%;
    top: -1px;
}

.jtpl-navigation li.jmd-nav__list-item-0 {
    margin:5px 0;
    padding:0;
}

.jtpl-navigation li.jmd-nav__list-item-0> a {
    background:transparent!important;
    padding: 0!important;
    margin: 3px 20px!important;
    color:inherit!important;
}

.jtpl-navigation .j-nav-variant-nested * {
    box-sizing: border-box;
}

.jtpl-navigation .j-nav-variant-nested a {
    padding: 0.2em 0;
    position: relative;
}

.jtpl-navigation .j-nav-variant-nested a:before,
.jtpl-navigation .j-nav-variant-nested a:after {
    position: absolute;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
}

.jtpl-navigation .j-nav-variant-nested a:before {
   content: '';
   position: absolute;
   width: 100%;
   transform: scaleX(0);
   height: 2px;
   z-index:-1;
   bottom: 0;
   left: 0;
   background-color: currentColor;
   transform-origin: bottom right;
   transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}

.jtpl-navigation .j-nav-variant-nested ul li ul a:before {
   height: 0px;   
}

.jtpl-navigation .j-nav-variant-nested a:after {
   left: 0;
   top: 0;
   padding: 0.5em 0;
   position: absolute;
   content: attr(data-hover);
   color: currentColor;
   white-space: nowrap;
   max-width: 0%;
   overflow: hidden;
}

.jtpl-navigation .j-nav-variant-nested a:hover:before,
.jtpl-navigation .j-nav-variant-nested .current a:before,
.jtpl-navigation .j-nav-variant-nested ul li.cc-nav-current>a:before,
.jtpl-navigation .j-nav-variant-nested ul li.cc-nav-parent>a:before {
   opacity: 1;
   width: 100%;
   transform: scaleX(1);
   transform-origin: bottom left;
}

.jtpl-navigation .j-nav-variant-nested a:hover:after,
.jtpl-navigation .j-nav-variant-nested .current a:after {
   max-width: 100%;
}


#cc-inner .jtpl-navigation  .jtpl-navigation .j-nav-level-2 {
   left: -100%!important;
}

.jtpl-breadcrumb, .jtpl-content, .jtpl-footer__inner, .jtpl-header__inner, .jtpl-sidebar__inner  {
   max-width: 1170px;
}



